import React from 'react';
import PropTypes from 'prop-types';
import { Container } from '../Grid';
import Image from '../Image';

import './styles.scss';

const Subfooter = ({ home, img, content, subcontent, setDangerously }) => {
  return (
    <Container
      fullWidth
      className="subfooter__container pb-0 pt-0 pr-0 pl-0 mb-0 mt-0"
    >
      <Image filename={img} className="subfooter__img" />
      <div className="subfooter__content-container">
        <div className="subfooter__content">
          {/* <Image filename={icon} /> */}
          <div>
            {setDangerously ? (
              <div dangerouslySetInnerHTML={{ __html: content }} />
            ) : (
              <div>{content}</div>
            )}

            <div className="text-sans-serif-large">{subcontent}</div>
          </div>
          {!home && <Image className="subfooter__content-shape" filename={'triangle-aqua.svg'}>
          </Image> }

          {home && <Image className="subfooter__content-shape" filename={'triangle-dark-blue.svg'}>
          </Image> }
        </div>
      </div>
    </Container>
  );
};

Subfooter.defaultProps = {
  setDangerously: false,
};

Subfooter.propTypes = {
  /** Filename of the image to be displayed in the subfooter. */
  img: PropTypes.string,
  /** Filename of the icon to be displayed in the subfooter. */
  icon: PropTypes.string,
  /** The text to be displayed in the subfooter. */
  content: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  /** The text to be displayed in the subfooter. */
  subcontent: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
};

export default Subfooter;
